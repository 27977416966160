import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/terms.scss";
import React, { Component } from "react";

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="terms-page">
          <h1>Terms of Service</h1>
          <h3>1. Provider, Scope of Application</h3>
          <p>
            (1) The provider and operator of the Platform is Photocase Addicts
            GmbH, Wissmannstrasse, 50823 Cologne, Germany (“PHOTOCASE”).
          </p>
          <p>
            (2) The following General Terms of Use contain the basic rules and
            terms for use of the Platform and govern all legal transactions and
            acts of similar nature effected between the User and the Platform.
            The User’s general terms and conditions shall not apply in
            connection with use of the Platform. This shall still apply even if
            PHOTOCASE does not expressly object to the User’s general terms and
            conditions.
          </p>
          <h3>2. Definitions</h3>
          <p>
            “User” shall mean any individual person or legal entity or
            association of persons who uses the Platform or has registered with
            the Platform.
          </p>
          <p>
            “Photographer” shall mean any User who posts a photograph on the
            Platform
          </p>
          <h3>3. Registration/User Account/Contract Closure</h3>
          <p>
            (1) Use of the Platform, unless it is accessed for visiting purposes
            only, is contingent upon the User registering and setting up a user
            account with its personal data (“Access Data”).
          </p>
          <p>
            (2) The User is under obligation to provide the data that is
            collected during registration both truthfully and in full. If
            subsequent to registration any of the data collected changes, the
            User must immediately update the particulars in its user account.
            The User must keep its Access Data secret and protect it from
            unauthorised third-party access. If the User mislays its Access
            Data, or if it discovers or suspects that its Access Data is being
            used by a third party, then it must immediately notify PHOTOCASE to
            this effect.
          </p>
          <p>
            (3) Using PHOTOCASE is not permitted for minors and for other
            individuals who have no or only limited legal capacity.
          </p>
          <h3>4. Object of the Platform</h3>
          <p>
            PHOTOCASE is a stock photo agency, and it provides Users with a
            digital marketplace where rights of use in photographs can be
            acquired in accordance with the License Conditions for Photos.
            Photographers and rightsholders can offer their photos for licensing
            in accordance with the Upload Conditions.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Terms;
