import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/imprint.scss";

class Imprint extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="imprint-page">
          <h1>Imprint</h1>
          <p>Photocase Addicts GmbH Wissmannstrasse 30 50823 Cologne Germany</p>
          <p>E-Mail: info@photocase.com</p>
          <p>Phone: +49 30 555 724 55 (10am to 4pm GMT, Mon to Fri.)</p>
          <h3>Copyright</h3>
          <p>
            The Licence Agreement for Photos applies to all photographs from the
            Photocase collection. Unless otherwise indicated, all other works
            published on the website, such as text and pictures, are copyright
            Photocase. Any duplication, dissemination, storage, transmission,
            transfer and reproduction of any content without the written
            permission of Photocase is expressly prohibited.
          </p>
          <h3>Notice of Liability</h3>
          <p>
            In spite of careful control of the content on Photocase, we do not
            accept any liability for the content of external links. The content
            of the linked pages is the sole responsibility of their operators.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Imprint;
