import React from "react";

const Star = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#f4ea2a",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="4023"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1013.923524 393.305928c-7.397466-22.839125-27.1237-39.451445-50.85003-42.904073L690.431499 310.767238 568.552904 63.770268c-10.63827-21.51497-32.548235-35.140265-56.543694-35.140264-24.008762 0-45.918727 13.625295-56.529368 35.140264L333.574641 310.767238 60.933669 350.401855c-23.728377 3.452628-43.468937 20.063925-50.865379 42.904073-7.426118 22.826846-1.224895 47.849702 15.934892 64.602214l197.245969 192.271688-46.53885 271.485661a62.939345 62.939345 0 0 0 25.064812 61.643843c11.004613 7.988936 23.982156 12.060663 37.042587 12.060662a62.8759 62.8759 0 0 0 29.35041-7.256249l243.840076-128.162706 243.826774 128.162706a62.830875 62.830875 0 0 0 29.35041 7.256249c13.061454 0 26.037974-4.071727 37.041564-12.060662 19.416173-14.104201 29.139609-37.987097 25.065835-61.643843l-46.553176-271.485661L997.986585 457.908142c17.17616-16.752512 23.375337-41.775369 15.936939-64.602214zM732.996858 628.172651L785.186393 932.332414 512.00921 788.741352 238.818723 932.332414l52.189535-304.159763L70.02061 412.76508l305.414334-44.383771L512.00921 91.654283 648.570172 368.381309l305.398985 44.383771-220.972299 215.407571z"
      fill={color}
      p-id="4024"
    ></path>
    <path
      d="M625.702394 400.013687L512.00921 169.727273 398.331375 400.013687l-254.196938 36.94435 183.959387 179.252188-17.359331 101.138266c-0.521885 1.846041-0.859575 3.74734-0.859576 5.747899 0 11.666691 9.425655 21.107695 21.079042 21.107695 10.455099 0 19.04983-7.594963 20.741352-17.585482l0.098237 0.042979 21.417756-125.120422L234.479914 466.292073l191.749802-27.842059 85.779494-173.785698 85.766191 173.785698 68.519423 9.933213c0.366343 0.098237 0.80227 0.112563 1.198289 0.169869l1.296526 0.210801v-0.070608c0.675381 0.084934 1.296526 0.197498 1.999536 0.197497 11.681017 0 21.122021-9.441004 21.122021-21.092345 0-10.8767-8.228389-19.711908-18.8114-20.881544l-47.397402-6.90321zM774.731295 464.135972l14.793908 2.141775-138.772323 135.263413L683.526242 792.5593l-171.517032-90.161283-207.474917 109.056594c-7.664548 3.15587-13.061454 10.693528-13.061455 19.500084 0 11.666691 9.425655 21.107695 21.106672 21.107695 3.550865 0 6.847951-0.973162 9.778694-2.494816l189.651006-99.701547 227.299388 119.484063-43.396282-253.140888 183.930735-179.252189-97.587401-14.202438c-1.50835-0.338714-3.057633-0.549514-4.66422-0.549514-11.638038 0-21.106671 9.439981-21.106672 21.106671 0 10.665899 7.94698 19.38752 18.246537 20.82424z"
      fill={color}
      p-id="4025"
    ></path>
  </svg>
);

export default Star;
