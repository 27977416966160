import React from "react";

const Pinterest = ({
  viewBox = "0 0 24 24",
  color = "#bfbfbf",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="5435"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M146.285714 341.138286C146.285714 130.267429 339.419429 0 534.857143 0 714.276571 0 877.714286 123.428571 877.714286 312.576c0 177.700571-90.843429 374.857143-293.156572 374.857143-48.018286 0-108.580571-23.990857-131.986285-68.571429-43.446857 171.995429-40.009143 197.705143-136.009143 329.142857L308.553143 950.857143l-5.156572-5.705143c-3.437714-35.986286-8.557714-71.424-8.557714-107.446857 0-116.553143 53.723429-285.147429 80.018286-398.299429-14.299429-29.147429-18.285714-64.585143-18.285714-96.585143 0-57.709714 40.009143-130.852571 105.142857-130.852571 48.018286 0 73.728 36.571429 73.728 81.700571 0 74.276571-50.285714 144.018286-50.285715 215.990858 0 49.152 40.557714 83.419429 87.990858 83.419428 131.437714 0 171.995429-189.696 171.995428-290.852571 0-135.424-96-209.152-225.718857-209.152-150.857143 0-267.446857 108.580571-267.446857 261.705143 0 73.728 45.129143 111.433143 45.129143 129.133714 0 14.848-10.861714 67.437714-29.696 67.437714-2.852571 0-6.838857-1.133714-9.728-1.718857-81.700571-24.576-111.433143-133.705143-111.433143-208.566857z"
      fill={color}
      p-id="5436"
    ></path>
  </svg>
);

export default Pinterest;
