import React, { Component } from "react";
import "../../style/common/headerBar.scss";
import Star from "../../icon/Star";
import HalfStar from "../../icon/HalfStar";
import ShoppingCart from "../../icon/ShoppingCart";
import logo from "../../image/photocase_logo.webp";
import logo2 from "../../image/logo2.png";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      default:
        break;
    }
  }

  render() {
    let { location } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <div className="logo" onClick={() => this.routePage("home")}>
            <img src={location.pathname === "/" ? logo : logo2} alt="" />
          </div>
          <div className="tab">
            <div className="li" onClick={() => this.routePage("pricing")}>
              Price
            </div>

            <div className="li" onClick={() => this.routePage("privacy")}>
              Terms of Service
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
