import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/pricing.scss";
import sec1 from "../image/divider.png";
import Instagram from "../icon/Instagram";
import Pinterest from "../icon/Pinterest";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.clicktoLocalstorage = this.clicktoLocalstorage.bind(this);
  }

  clicktoLocalstorage(price, title) {
    let cartArr = {
      price: price,
      title: title,
    };

    localStorage.setItem("cartArr", JSON.stringify(cartArr));
    let { history } = this.props;
    history.push("/Checkout");
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="pricing-page">
          <div className="title">The Choice Is Yours</div>
          <div className="tl2">
            Short and painless via Direct Purchase or cheap and convenient with
            a Credit Bundle.
          </div>
          <div className="tl2">
            The price depends on the required image size.
          </div>
          <div className="bg">
            <div className="title">Image Prices</div>
            <div className="desc">
              Price of a Base License via Direct Purchase or in Credits.
            </div>
          </div>
          <div className="bg2">
            <div className="contain"></div>
            <div className="row">
              <div className="col">
                <div className="head">
                  <div className="size">S</div>
                  <div className="size-des">Web size</div>
                  <div className="price">$15</div>
                  <div className="num">5 Credits</div>
                </div>
                <div className="body1">
                  Suitable for electronic media from thumbnail to hero shot
                </div>
                <div className="foot1">0.8 Megapixel, 72 dpi</div>
              </div>
              <div className="col">
                <div className="head">
                  <div className="size">M</div>
                  <div className="size-des">Print Size</div>
                  <div className="price">$30</div>
                  <div className="num">10 Credits</div>
                </div>
                <div className="body1">
                  Perfect for the design of print media up to approx. A4
                </div>
                <div className="foot1">4 Megapixel, 300 dpi</div>
              </div>
              <div className="col">
                <div className="head">
                  <div className="size">L</div>
                  <div className="size-des">Full Size</div>
                  <div className="price">$45</div>
                  <div className="num">15 Credits</div>
                </div>
                <div className="body1">
                  The right choice for large prints or just to stay flexible
                </div>
                <div className="foot1">Max. resolution, 300 dpi</div>
              </div>
            </div>
          </div>
          <div className="bg3">
            For editorial use, a discount of 20% applies.
          </div>
          <div className="sec1">
            <div className="left">
              <div className="head">Direct Purchase</div>
              <div className="des">
                With a direct purchase only the currently desired images are
                paid by credit card, Paypal or bank transfer. Ideal for
                customers with single or occasional content requirements.
              </div>
            </div>
            <div className="img">
              <img src={sec1} alt="" />
            </div>
            <div className="right">
              <div className="head">Direct Purchase</div>
              <div className="des">
                Price aware clients benefit from the attractive discounts of
                Credit packages to download their stock photos. Best option for
                companies with large or frequent image needs.
              </div>
            </div>
          </div>
          <div className="sec2">
            <div className="title">Credit Bundles</div>
            <div className="title-b">
              Loyalty pays off. The bigger the credit package, the higher the
              discount. And no worries: <br />
              <span>Photocase Credits never expire </span>!
            </div>
            <div className="table">
              <div className="thead">
                <div className="li">Credit</div>
                <div className="li">Price</div>
                <div className="li">Discount</div>
                <div className="li"></div>
              </div>
              <div className="tbody">
                <div className="li">25 Credits</div>
                <div className="li">$60</div>
                <div className="li">20%</div>
                <div
                  className="li"
                  onClick={() => this.clicktoLocalstorage(60, 20)}
                >
                  Buy now
                </div>
              </div>
              <div className="tbody">
                <div className="li">50 Credits</div>
                <div className="li">$110</div>
                <div className="li">25%</div>
                <div
                  className="li"
                  onClick={() => this.clicktoLocalstorage(110, 50)}
                >
                  {" "}
                  Buy now
                </div>
              </div>
              <div className="tbody">
                <div className="li">100 Credits</div>
                <div className="li">$210</div>
                <div className="li">30%</div>
                <div
                  className="li"
                  onClick={() => this.clicktoLocalstorage(210, 100)}
                >
                  {" "}
                  Buy now
                </div>
              </div>
              <div className="tbody">
                <div className="li">250 Credits</div>
                <div className="li">$480</div>
                <div className="li">35%</div>
                <div
                  className="li"
                  onClick={() => this.clicktoLocalstorage(480, 250)}
                >
                  {" "}
                  Buy now
                </div>
              </div>
              <div className="tbody">
                <div className="li">500 Credits</div>
                <div className="li">$890</div>
                <div className="li">40%</div>
                <div
                  className="li"
                  onClick={() => this.clicktoLocalstorage(890, 500)}
                >
                  {" "}
                  Buy now
                </div>
              </div>
              <div className="tbody">
                <div className="li">1000 Credits</div>
                <div className="li">$1630</div>
                <div className="li">45%</div>
                <div
                  className="li"
                  onClick={() => this.clicktoLocalstorage(1630, 1000)}
                >
                  {" "}
                  Buy now
                </div>
              </div>
            </div>
          </div>
          <div className="sec3">
            <h2>We are here for you</h2>
            <p>
              If you have questions or need help, <br />
              feel free to get in contact.
            </p>
            <div className="p">
              Live Chat <br /> info@photocase.de <br /> +49 30 55572455
            </div>
            <div className="span">
              Or via classic land mail to <br />
              Photocase Addicts GmbH, Wissmannstraße 30, 50823 Köln, Germany.
            </div>
            <div className="row">
              <Instagram size={25} color="#fff" className="icon" />
              <Pinterest size={25} color="#fff" className="icon" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Pricing;
