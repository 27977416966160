import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import Facebook from "../../icon/Facebook";
import Instagram from "../../icon/Instagram";
import Pinterest from "../../icon/Pinterest";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "terms":
        history.push("/Terms");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "imprint":
        history.push("/Imprint");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footerBar">
          <div className="til">
            Sign up for our handmade newsletter full of inspiration and a
            discounts.
          </div>
          <div className="row">
            <Facebook size={25} className="icon" />
            <Instagram size={25} className="icon" />
            <Pinterest size={25} className="icon" />
          </div>
          <div className="row">
            <p onClick={() => this.routePage("imprint")}>Imprint</p>
            <p onClick={() => this.routePage("privacy")}>Privacy Policy</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
