import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import Home from "./components/Home";
import Checkout from "./components/Checkout";
import Pricing from "./components/Pricing";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import Imprint from "./components/Imprint";
import Success from "./components/Success";
import Fail from "./components/Fail";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            {/* <Route
              path="/ProductDetail/:id"
              render={(props) => (
                <ProductDetail key={props.match.params.id} {...props} />
              )}
            /> */}
            <Route path="/Success" component={Success}></Route>
            <Route path="/Fail" component={Fail}></Route>
            <Route path="/Imprint" component={Imprint}></Route>
            <Route path="/Term" component={Terms}></Route>
            <Route path="/Privacy" component={PrivacyPolicy}></Route>
            <Route path="/Pricing" component={Pricing}></Route>
            <Route path="/Checkout" component={Checkout}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </React.Fragment>
      </HashRouter>
    );
  }
}
export default App;
