import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/privacy.scss";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="privacy-page">
          <h1>Privacy Policy</h1>
          <h3>1. Responsible body</h3>
          <p>
            Responsible body for the collection, processing and use of your
            personal data within the meaning of the European Data Protection
            Regulation is Photocase Addicts GmbH, Wissmannstrasse 30, 50823
            Cologne, Germany with its managing director Dittmar Frohmann. If you
            wish to object to the collection, processing or use of your data by
            Photocase in accordance with this Privacy Policy as a whole or for
            individual measures, you can send your objection by e-mail or letter
            to:
          </p>
          <p>Photocase Addicts GmbH Wissmannstrasse 30 50823 Cologne Germany</p>
          <p>Mail: info@photocase.de</p>
          <h3>2. Collection, processing and use of personal data</h3>
          <h3>2.1 Personal data</h3>
          <p>
            At Photocase we offer personalized rights of use (licenses) for
            photos. In order to transfer these rights of use to you, we require
            some of your personal data.
          </p>
          <p>
            In addition, we would like to offer you personalized customer
            service and individual offers that are always tailored to your
            needs. For this purpose we collect personal data, communication
            data, contract data, licensing data, website behavior and
            advertising-related data. In doing so, we always comply with the
            storage and deletion periods prescribed by law.
          </p>
          <p>
            Statistical data that we collect when you visit our web shop and
            that cannot be directly associated with you, do not fall under this
            law. These are for example statistics about which pages of our shop
            are particularly popular or how many users visit certain pages of
            the Photocase Shop.
          </p>
          <h3>2.2 Purpose and lawfulness of the data processing</h3>
          <p>
            The data processing is carried out according to the principles
            according to Art. 5 GDPR and the legality is always based on the
            conditions required by the legislator according to Art. 6 (1) GDPR.
          </p>
          <p>
            We offer our customers personalized rights of use (licenses) for
            photos as well as an individual buying experience. To give you this,
            it is important to understand and analyze your personal needs. This
            also includes your customer history in the online shop, in e-mail
            traffic, on the phone or via a live chat. In particular, observation
            of customer interaction with Photocase products helps us to make
            your purchase as easy as possible. In addition, we conclude a
            purchase agreement with you during the ordering process. Both can
            only be achieved by collecting and processing your data, which we
            also need to fulfill our legal obligations. Basically, we stand for
            data minimization and process only data that is necessary for the
            purposes mentioned above. This data processing is transparent to you
            at all times. If the data processing goes beyond the above-mentioned
            purposes, we will ask for your consent in advance.
          </p>
          <h3>2.3 Collection, processing and use of your personal data</h3>
          <p>
            Privacy is very important to us. Therefore, we strictly adhere to
            the legal provisions of the EU Data Protection Directive and the
            German Telemedia Act when collecting, processing and using your
            personal data. We collect, store and process your data in order to
            process your purchase, including any subsequent warranties, for our
            services, the technical administration and our own marketing
            purposes. Your personal data will only be passed on to third parties
            or otherwise transmitted if this is necessary for the purpose of
            contract execution or billing or if you have previously consented to
            it’s transfer.
          </p>
          <h3>2.3.1 Data transfer for fulfillment of the contract</h3>
          <p>
            In order to fulfill the sales contract and process payments, we will
            pass on the necessary payment data to the credit institution
            commissioned with the payment and any payment service providers
            commissioned by us or to the payment service selected by you in the
            ordering process. Any consent given for the storage of your data
            during order processing can be withdrawn at any time with effect for
            the future.
          </p>
          <p>
            For your order we need your correct name, address and payment
            details. We need your e-mail address so that we can confirm the
            order and communicate with you. We also use these for your
            identification (customer login). You will also receive your order
            confirmation and invoice via your e-mail address.
          </p>
          <p>
            A deletion of your personal data takes place, as long as this does
            not conflict with statutory retention requirements and if you have
            asserted a cancellation claim, if the data is no longer required to
            fulfill the purpose of the storage or if their storage is
            inadmissible for other legal reasons.
          </p>
          <h3>2.4 Use of your data for advertising purposes</h3>
          <p>
            In addition to processing your data to process your purchase at
            Photocase, we also use your information to communicate with you
            about your orders, specific products or marketing promotions, and to
            recommend products or services that may interest you. If we receive
            your e-mail address in connection with the sale of a good or service
            and you have not objected to this, we reserve the right to regularly
            send you offers for similar products, such as those already
            purchased, from our range by e-mail , You may object to the use of
            your personal data for advertising purposes at all times or for
            individual measures without incurring other than the transmission
            costs according to the basic rates. A short written message (e-mail,
            letter, etc.) to the contact details referred to in point 1 is
            sufficient.
          </p>
          <p>
            <b>2.4.1 Use of data when registering for the e-mail newsletter</b>
          </p>
          <p>
            Our newsletter is a so-called “opt-in newsletter,” which means that
            we will only send you a newsletter by e-mail if you have explicitly
            confirmed to us that you want to receive it. In addition you can
            indicate your preference with a check mark during the registration
            or administration of your customer account. Should you later no
            longer wish to receive newsletters from us, you can cancel at any
            time, without any costs other than the transmission costs according
            to the basic rates. A written message (e-mail, letter, etc.) to the
            contact details referred to in point 1 is sufficient. Of course
            every newsletter contains an unsubscribe link.
          </p>
          <p>
            <b>
              2.4.2 Use of data for product recommendations by e-mail and your
              right to object
            </b>
          </p>
          <p>
            As a customer of Photocase you receive regular product
            recommendations from us by mail. You will receive these product
            recommendations from us, regardless of whether you have subscribed
            to a newsletter, if we have received your e-mail address in
            connection with the sale of a good or service and you have not
            explicitly objected to receiving them. We want to send you
            information about products from our offer in this way.
          </p>
          <p>
            If you do not want to receive any product recommendations or a total
            of no more promotional messages from us, you can object to this at
            any time without incurring any costs other than the transmission
            costs according to the basic rates. A written message (e-mail,
            letter, etc.) to the contact details referred to in point 1 is
            sufficient. Of course, every e-mail contains an unsubscribe link.
          </p>
          <p>
            <b>2.4.3 Individual advertising for you</b>
          </p>
          <p>
            Information that we receive from you helps us to constantly improve
            your shopping experience and to make it customer-friendly and
            individual. The information you provide and automatically generate
            is used to design advertising tailored to you and your interests. We
            use existing information, such as email receipt and read receipt,
            computer and Internet connection information, operating system and
            platform, your order history, service history, date and time you
            visited the website, and products you viewed.
          </p>
          <p>
            We use this information exclusively in a pseudonymised form. By
            analyzing and evaluating this information, we are able to improve
            our website, as well as to send you individual advertising. That
            means advertising that recommends products that might be of interest
            to you. Our goal is to make our advertising more useful and
            interesting to you. Therefore, the evaluation and analysis of the
            pseudonymized data that we have collected, helps us not to randomly
            send you advertisements. Rather, we send you advertising, such as
            newsletters, product recommendations, by e-mail or advertising by
            mail, which correspond to your areas of interest. In this respect,
            for example, we also compare which of our advertising emails you
            open to avoid unnecessary emails being sent to you.
          </p>
          <p>
            If you do not want to receive personalized advertising, you can opt
            out at any time. A written message (e-mail, letter, etc.) to the
            contact details referred to in point 1 is sufficient.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
