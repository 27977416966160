import React, { Component } from "react";
import "../style/home.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import sec1 from "../image/sec1.jpg";
import bg from "../image/bg.jpg";
import Star from "../icon/Star";
import sec2 from "../image/sec2.jpg";
import sec3 from "../image/sec3.jpg";
import sec4 from "../image/sec4.jpg";
import sec5 from "../image/sec5.jpg";
import sec6 from "../image/sec6.jpg";
import sec7 from "../image/sec7.jpg";
import sec8 from "../image/sec8.jpg";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <div className="left">
              <img src={bg} alt="" />
              <div className="txt">
                Buy Stock Photos <br /> Who Know What <br /> They Want
              </div>
            </div>
            <div className="right">
              <img src={sec1} alt="" />
            </div>
            <div className="btn" onClick={()=>this.routePage('pricing')}>
              <Star size={15} color="#321845" /> <p>View image prices</p>
            </div>
          </div>
          <div className="sec2">
            <h2>
              Get Out Of The Mainstream And Buy Unique Royalty-Free Stock Photos
              !
            </h2>
            <p>
              Stock photos that show real people with real emotions. Images that
              represent reality in its <br /> diversity while matching your
              current project and budget. Agencies with aspirations, brands with
              brains, publishers with panache and anyone who wants to look
              different buy royalty-free images <br /> at Photocase.
            </p>
          </div>
          <div className="sec3">
            <div className="title">New Stock Imagery</div>
            <div className="row">
              <img src={sec2} alt="" />
              <div className="mask-txt">
                <h3>Best of June 2024</h3>
                <p>Favourite images from this month</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img src={sec3} alt="" />
                <div className="mask-txt">
                  <h3>Faces</h3>
                  <p>Individuality</p>
                </div>
              </div>
              <div className="col">
                <img src={sec4} alt="" />
                <div className="mask-txt">
                  <h3>Faceless</h3>
                  <p>Anonymity</p>
                </div>
              </div>
            </div>
          </div>
          <div className="sec4">
            <div className="li">
              <div className="title">Unique Images</div>
              <div className="desc">
                {" "}
                Annoyed by boring stock photography and dull photo sites ?
                Looking for quality images that have not yet been used all over?
                This is exactly what Photocase offers.
              </div>
            </div>
            <div className="li">
              <div className="title">Strong Selection</div>
              <div className="desc">
                No one needs millions of almost identical stock images.
                Photocase is proud to have developed one of the strictest
                selection processes of all stock agencies.
              </div>
            </div>
            <div className="li">
              <div className="title">Comfortable Handling</div>
              <div className="desc">
                Next to great stock imagery we enable comfortable handling and a
                neat workflow. Use in social media included. Virtually all
                commercial uses included.
              </div>
            </div>
            <div className="li">
              <div className="title">Easy Payment</div>
              <div className="desc">
                Simply pay per download to get exactly what you want. Or take
                advantage of the attractive discounts and the comfort of
                Photocase Credit packages.
              </div>
            </div>
          </div>
          <div className="sec5">
            <div className="title">Photocase Newsletter</div>
            <div className="desc">
              Get the monthly newsletter full of inspiration. Check out the
              latest stock photos <br /> fresh from the picture desk, thematic
              galleries and occasional discounts.
            </div>
          </div>
          <div className="sec6">
            <div className="title">More Stock Photo Collections</div>
            <div className="row">
              <div className="col">
                <img src={sec5} alt="" />
                <div className="mask-txt">
                  <h3>Best of April 2024</h3>
                  <p>Favourite images from this month</p>
                </div>
              </div>
              <div className="col">
                <img src={sec6} alt="" />
                <div className="mask-txt">
                  <h3>Order</h3>
                  <p>01010101010</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img src={sec7} alt="" />
                <div className="mask-txt">
                  <h3>Easter</h3>
                  <p>Bunny Rabbits, Eggs & Flowers</p>
                </div>
              </div>
              <div className="col">
                <img src={sec8} alt="" />
                <div className="mask-txt">
                  <h3>Here we go!</h3>
                  <p>The legalisation</p>
                </div>
              </div>
            </div>
          </div>
          <div className="sec7">
            <div className="title">Creativity does not come for free</div>
            <div className="desc">
              Royalty-free images are available on the market in all price
              ranges from ridiculously cheap to overly expensive. Even stock
              agencies offering free photos can be found via search engines in
              large numbers. But if everyone just uses free stock photos, it
              would be the end of creativity. There are various types of stock
              photo agencies, all claiming to offer only high-quality photos for
              commercial use in ads, websites, or social media. The main
              differences consist in the style and quality of the image
              material, the pricing and the attitude towards the represented
              artists. Stock photo sites help to limit the legal risk by doing
              the footwork, securing high-quality content, compiling photo
              libraries under royalty free-licenses Of course, not everyone can
              afford contract photography with exclusive rights, but somewhere
              in between lies the truth. We see ourselves as a mediator between
              the desire of image buyers to buy premium stock photography at the
              lowest possible price and the need of authors to offer their work
              at the highest possible price.
            </div>
            <div className="btn" onClick={() => this.routePage("pricing")}>
              Click here to see the photo prices.
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
